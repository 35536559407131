import { Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from '../svc/globals';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Company } from '../svc/company/company';
import { CompanyUtils } from '../svc/company/company-utils';
import { forkJoin, interval, Subscription } from 'rxjs';
// import { WorkhourService2 } from '../svc/company/workhour/workhour2.service';
import { ProfileService } from 'src/app/svc/company/company-user/profile.service';
import { AgendamentoService } from '../svc/company/agendamento/agendamento.service';
import { take } from 'rxjs/operators';
import { RootStylesService } from '../svc/root-styles/root-styles.service';
import { set } from 'date-fns';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyService } from '../svc/company/company.service';
// import { AssinaturaService } from '../svc/company/plan/assinatura.service';
import { CashRegisterService } from '../../../src/app/services/pdv-cash-register/cash-register.service';
import { PermissionsService } from '../svc/company/permissions/permissions.service';
import { AssinaturaService } from '../svc/company/plan/assinatura.service';
import { CompanyUnlockDateService } from '../svc/company/comp-unlock-date/company-unlock-date.service';
import { PlanService } from '../svc/company/plan/plan.service';
import { Plan } from '../svc/company/plan/plan';
import Pusher, { Channel } from 'pusher-js';
import { MercadoPagoService } from '../svc/company/mercado-pago-gateway/mercadopago.service';

declare var bootstrap: any;

@Component({
  selector: 'app-ambiente-agenda',
  templateUrl: './ambiente-agenda.component.html',
  styleUrls: ['./ambiente-agenda.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.25s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'slideAnimation',
      [
        transition(
          ':enter',
          [
            style({ left: '-70%' }),
            animate('0.25s ease-out',
              style({ left: '0' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ left: '0' }),
            animate('0.25s ease-in',
              style({ left: '-70%' }))
          ]
        )
      ]
    ),
    trigger(
      'refreshPromptAnimation',
      [
        transition(
          ':enter',
          [
            style({ right: '-20rem' }),
            animate('0.25s ease-out',
              style({ right: '1%' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ right: '1%' }),
            animate('0.25s ease-in',
              style({ right: '-20rem' }))
          ]
        )
      ]
    )
  ]
})
export class AmbienteAgendaComponent implements OnInit, OnDestroy {

  showConfigutarions = false;
  showAtalhos = false;
  showProducts = false;
  showTickets = false;
  showPedidos = false;
  showCartoes = false;
  showClientes = false;
  showFrete = false;
  showSuporte = false;
  company: Company
  cash = 0;
  selectedButton: number | null = null;

  private routerEventSub$: Subscription;
  triggerBool: boolean = true;   // just to trigger the reload of subscription feedback

  shopStatus: boolean;

  linkCopied = false;

  isAdmin = false;
  nomeCompany: string;

  alarmTimeOut;
  agendaCountTimeout;
  elementsHidden: boolean;

  isCashRegisterOpen: boolean;

  currentTheme: string = 'theme-default';
  company_id = CompanyUtils.getCompanyId();

  //permissoes do usuario
  visualizarAgenda = false;
  editarAgenda = false;
  historicoAgenda = false;
  financeiro = false;
  relatoriosFaturamento = false;
  listaEspera = false;
  clientes = false;
  gerenciarFuncionarios = false;
  gerenciarRecessos = false;
  planosAssinaturas = false;
  controledecaixa = false;
  comandas = false;
  produtoseestoque = false;

  assinaturaFim: string;
  companyPaymentStatus: string;

  // Estados das categorias
  isCadastrosAgendaExpanded: boolean = false;
  isFinanceiroExpanded: boolean = false;
  isVerAgendaExpanded: boolean = false;
  isConfigAgendaExpanded: boolean = false;

  isSupportModalVisible: boolean;

  selectedTheme: string = 'light';  // Padrão como tema claro

  public copyPasteCode: string = '';

  paymentStatus = 'pending';
  pollInterval: any;

  isAgendaLiberadaModalVisible: boolean = false;
  isPlanoEscolhaModalVisible: boolean = false;

  selectedPlano: 'mensal' | 'semestral' | 'anual' | 'vitalicio' | null = null;

  isUpgradeModalVisible: boolean = false;
  currentSubscription: any = null;
  upgradePlans: any[] = [];
  phoneCompany: string;
  emailCompany: string;
  nomeResponse: string;
  
  assinaturaTrial: boolean;

 subscription: Subscription;

  cardsLoaded: boolean = false;

  planAtual: Plan;

  pollIntervalUnpaid: any;

  pusher: Pusher;

  channel: Channel;

  private subscriptionStatusCheck: Subscription;

  constructor(
    public globals: Globals,
    public router: Router,
    // private whSvc: WorkhourService2,
    private compSvc: ProfileService,
    private agendamentoSvc: AgendamentoService,
    private rootStylesService: RootStylesService,
    private ngxLoaderSvc: NgxUiLoaderService,
    private companySvc: CompanyService,
    // private assinaturaService: AssinaturaService,
    private cashRegisterService: CashRegisterService,
    private permissions: PermissionsService,
    private assinaturaService: AssinaturaService,
    private companyUnlockSvc: CompanyUnlockDateService,
    private assinatSvc: AssinaturaService,
    private planSvc: PlanService,
    private mercadoPagoService : MercadoPagoService,
    private route: ActivatedRoute
  ) {
    this.company = new Company();
    
  }

  ngOnInit(): void {
    // =========================================
    // 1. Carrega tema e dados locais do storage
    // =========================================
    this.selectedTheme = localStorage.getItem('selectedTheme') || 'light';
    const savedSelection = localStorage.getItem('selectedButton');
    if (savedSelection !== null) {
      this.selectedButton = Number(savedSelection);
    }
  
    // =========================================
    // 2. Observa se o caixa está aberto
    // =========================================
    this.cashRegisterService.currentCashRegisterState.subscribe((isOpen) => {
      this.isCashRegisterOpen = isOpen;
    });
  
    // =========================================
    // 3. Ajusta a sidebar conforme largura de tela
    // =========================================
    if (window.screen.width < 1000) {
      document.getElementById('sidebar').style.left = '-100%';
      document.getElementById('sidebar').style.width = '100vw';
      document.getElementById('sidebar').style.minWidth = '100vw';
      document.getElementById('sidebar').style.zIndex = '1';
      document.getElementById('sidebar').style.position = 'absolute';
      document.getElementById('pageContent').style.width = '100vw';
      this.globals.showMenuBtn = true;
    } else {
      this.globals.showMenuBtn = false;
    }
  
    // =========================================
    // 4. Execução de chamadas HTTP em paralelo
    // =========================================
    forkJoin({
      permissions: this.permissions.getUserPermissions(CompanyUtils.getUserId()),
      purchasedPlan: this.planSvc.getPurchasedPlanForCompany(CompanyUtils.getCompanyId()),
      link: this.compSvc.getMeusLinks(CompanyUtils.getCompanyId()),
      companyData: this.companySvc.getAgendamentoCompData(CompanyUtils.getCompanyId())
    }).subscribe({
      next: ({ permissions, purchasedPlan, link, companyData }) => {
        // 4.1. Trata permissões do usuário
        CompanyUtils.setUserAccess(permissions[0].access_ids);
        this.visualizarAgenda = CompanyUtils.canVisulizarAgenda();
        this.editarAgenda = CompanyUtils.canEditarAgenda();
        this.historicoAgenda = CompanyUtils.canHistoricoAgenda();
        this.financeiro = CompanyUtils.canFinanceiro();
        this.relatoriosFaturamento = CompanyUtils.canRelatoriosFaturamento();
        this.listaEspera = CompanyUtils.canListaEspera();
        this.clientes = CompanyUtils.canClientes();
        this.gerenciarFuncionarios = CompanyUtils.canGerenciarFuncionarios();
        this.gerenciarRecessos = CompanyUtils.canGerenciarRecessos();
        this.planosAssinaturas = CompanyUtils.canPlanosAssinaturas();
        this.controledecaixa = CompanyUtils.canControleDeCaixa();
        this.comandas = CompanyUtils.canComandas();
        this.produtoseestoque = CompanyUtils.canProdutosEEstoque();
  
        // 4.2. Trata plano adquirido
        this.planAtual = purchasedPlan[0];
        if (purchasedPlan[0].current_period_end) {
          localStorage.setItem('currentEnd', purchasedPlan[0].current_period_end.date);
        }
        localStorage.setItem('companyStatusPayment', purchasedPlan[0].status);
        if (this.planAtual) {
          // Método que você já tinha para buscar detalhes no pagar.me
          this.getAssinaturaDetailsPagarme();
        }
  
        // 4.3. Trata link de agendamento
        this.company = link[0];
        // Mantém a imagem do cartão
        this.company.img_cartao_link = CompanyUtils.getImgCartaoLink();
  
        // 4.4. Trata dados adicionais da empresa
        this.currentTheme = !companyData.linkThemeName ? 'theme-default' : companyData.linkThemeName;
        this.phoneCompany = companyData.phone;
        this.emailCompany = companyData.email;
        this.nomeResponse = companyData.nome_responsavel;
      },
      error: (err) => {
        console.error('Erro ao carregar dados em paralelo:', err);
      }
    });

    this.getPurchasedPlan();
  
    // =========================================
    // 5. Verifica se é admin
    // =========================================
    this.isAdmin = (CompanyUtils.getUserType() !== 2);
  
    // =========================================
    // 6. Define infos da company e preferência de alarme
    // =========================================
    this.company.img_cartao_link = CompanyUtils.getImgCartaoLink();
    this.company.nome_fantasia = CompanyUtils.getCompanyName();
    this.nomeCompany = this.company.nome_fantasia;
    this.globals.playAlarm = CompanyUtils.getAlarmPreference();
    this.getLink();
  
    // =========================================
    // 7. Observa eventos de navegação (subscription feedback)
    // =========================================
    this.routerEventSub$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/ambiente/agenda/agendamento-list') {
          this.triggerBool = !this.triggerBool;
        }
      }
    });
  
    // =========================================
    // 8. Se configurado, inicia verificação periódica de agendamentos
    // =========================================
    if (this.globals.checkAgenda) {
      this.getAgendaCount(
        this.agendamentoSvc,
        this.globals,
        this.alarmTimeOut,
        this.agendaCountTimeout,
        this.loopAlarm
      );
    }
  
    // =========================================
    // 9. Aplica estilos de acordo com o tema
    // =========================================
    this.setRootStyles();
  
    // =========================================
    // 10. Verifica se está no período de trial
    // =========================================
    this.verTrialInfo();
  
    // =========================================
    // 11. Espera 5s e então define status pagamento + data término
    // =========================================
    setTimeout(() => {
      this.companyPaymentStatus = localStorage.getItem('companyStatusPayment');
      const assinaturaFim = localStorage.getItem('currentEnd');
      if (assinaturaFim) {
        const data = new Date(assinaturaFim);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        this.assinaturaFim = `${dia}/${mes}/${ano}`;
      }
      this.cardsLoaded = true;
    }, 20000);

    this.subscriptionStatusCheck = interval(3600000) 
      .subscribe(() => {
        this.verificarExpiracaoAssinatura();
      });
      this.verificarExpiracaoAssinatura();
    this.verificarStatusRecorrente();
    this.route.queryParams.subscribe((params) => {
      if (params['callVerificarStatus'] === '1') {
        this.verificarStatus();
      }
    });
  }
  

  ngOnDestroy(): void {
    this.routerEventSub$.unsubscribe();
    clearTimeout(this.alarmTimeOut);
  
    // Se já existir outro polling, você também limpa.
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  
    // Limpa o polling de 1 em 1 minuto
    if (this.pollIntervalUnpaid) {
      clearInterval(this.pollIntervalUnpaid);
    }
    if (this.pusher) {
      this.pusher.disconnect();
    }

    if (this.subscriptionStatusCheck) {
      this.subscriptionStatusCheck.unsubscribe();
    }
  }
  
  

  isLessThan90Days(): boolean {
    if (!this.assinaturaFim) {
      return false;
    }
    
    // Supõe que this.assinaturaFim esteja em "DD/MM/YYYY"
    const [diaStr, mesStr, anoStr] = this.assinaturaFim.split('/');
    const dia = parseInt(diaStr, 10);
    const mes = parseInt(mesStr, 10) - 1;  // Mês em JS vai de 0 a 11
    const ano = parseInt(anoStr, 10);
  
    // Monta o objeto Date
    const dataFim = new Date(ano, mes, dia); 
  
    if (isNaN(dataFim.getTime())) {
      return false;
    }
  
    const dataHoje = new Date();
    const diffMs = dataFim.getTime() - dataHoje.getTime();
    const diffDias = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    
    return diffDias <= 90;
  }
  

  // Método para navegar e abrir o modal
  navigateAndOpenModal(modalId: string, buttonIndex: number): void {
    this.closeSideBar();
    this.selectedButton = buttonIndex;

    this.router.navigate(['/ambiente/agenda/agendamento-list']).then(() => {
      setTimeout(() => {
        this.openBootstrapModal(modalId);
      }, 300); // Pequeno atraso para garantir que a navegação foi concluída
    });
  }

  // Função para abrir modal usando Bootstrap diretamente
  openBootstrapModal(modalId: string): void {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modalInstance = new bootstrap.Modal(modalElement);
      modalInstance.show();
    }
  }

  getCompanyData() {
    // will return whatsapp_phone and company data
    // this.ngxLoaderSvc.start();
    this.companySvc.getAgendamentoCompData(this.company_id).subscribe(
      (result) => {
        // this.ngxLoaderSvc.stop();
        this.currentTheme = !result.linkThemeName ? 'theme-default' : result.linkThemeName;
        this.phoneCompany = result.phone;
        this.emailCompany = result.email;
        this.nomeResponse = result.nome_responsavel;
        // console.log(result);
      },
      (err) => {
        // this.ngxLoaderSvc.stop();
        console.log(err);
      }
    );
  }

  getPermissions(): void {
    this.permissions.getUserPermissions(CompanyUtils.getUserId()).pipe(take(1)).subscribe({
      next: (result) => {
        // console.log('===', result);
        CompanyUtils.setUserAccess(result[0].access_ids);
        this.visualizarAgenda = CompanyUtils.canVisulizarAgenda();
        this.editarAgenda = CompanyUtils.canEditarAgenda();
        this.historicoAgenda = CompanyUtils.canHistoricoAgenda();
        this.financeiro = CompanyUtils.canFinanceiro();
        this.relatoriosFaturamento = CompanyUtils.canRelatoriosFaturamento();
        this.listaEspera = CompanyUtils.canListaEspera();
        this.clientes = CompanyUtils.canClientes();
        this.gerenciarFuncionarios = CompanyUtils.canGerenciarFuncionarios();
        this.gerenciarRecessos = CompanyUtils.canGerenciarRecessos();
        this.planosAssinaturas = CompanyUtils.canPlanosAssinaturas();
        this.controledecaixa = CompanyUtils.canControleDeCaixa();
        this.comandas = CompanyUtils.canComandas();
        this.produtoseestoque = CompanyUtils.canProdutosEEstoque();
      },
      error: (error) => {
        console.log('error getting permissions', error);
      },
    });
  }

  toggleSupportModal() {
    this.isSupportModalVisible = !this.isSupportModalVisible;
  }

  // Funções para alternar o estado das categorias
  toggleCadastrosAgenda() {
    this.isCadastrosAgendaExpanded = !this.isCadastrosAgendaExpanded;
  }

  toggleMinhaAgenda() {
    this.isVerAgendaExpanded = !this.isVerAgendaExpanded;
  }

  toggleFinanceiro() {
    this.isFinanceiroExpanded = !this.isFinanceiroExpanded;
  }

  toggleConfigAgenda() {
    this.isConfigAgendaExpanded = !this.isConfigAgendaExpanded;
  }

  setRootStyles() {
    const styleObj = CompanyUtils.getPageTheme();
    this.rootStylesService.setRootStyles(styleObj);
  }

  goToCompanyProfile() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/user-profile');
  }

  // goToCompanyworkhours() {
  //   this.router.navigateByUrl('ambiente/agenda/workhours');
  // }

  toggleElements(): void {
    const collapsibleElements = document.querySelectorAll('.collapsible');
    collapsibleElements.forEach(el => {
      el.classList.toggle('collapsed');
    });

    this.elementsHidden = !this.elementsHidden;
  }


  goToUsagePlans() {
    this.closeSideBar();
    this.verificarStatus();
    this.toggleSupportModal();
    this.closePlanoEscolhaModal();
    this.closeAgendaLiberadaModal();
    this.closeUpgradeModal();
  }

  goToNewPassword() {
    this.router.navigateByUrl('ambiente/agenda/new-password');
  }

  goToPrincipalClients() {
    this.router.navigateByUrl('ambiente/agenda/top-clients');
  }

  goToEditLogin() {
    this.router.navigateByUrl('ambiente/agenda/edit-login');
  }

  goToCreatePlan() {
    this.router.navigateByUrl('ambiente/agenda/usage-plans');
    this.closeSideBar();
  }

  goToMeuLink() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/meu-link');

  }

  goToIntegracaoPagamento() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/integracao-pagamento');
  }

  goToClubeAssinaturas() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/clube-de-assinaturas');
  }

  goToDashboard() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/dashboard');
  }

  exitUser() {
    localStorage.clear();
    this.globals.companyNoPay = false;
    this.router.navigateByUrl('agenda/login');

  }

  goToLinkTheme() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/link-theme');
  }

  goToClientes() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/clients/1');
  }

  goToHome() {
    this.router.navigateByUrl('ambiente/agenda/');
  }

  goToCaixa() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/pdv/2');
  }

  goToComandas() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/pdv/3');
  }

  goToProdutos() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/products');
  }

  goToAgendamentoCadastro(tab?) {
    this.closeSideBar();
    if (tab){
      this.router.navigate(['/ambiente/agenda/agendamento-cadastro/', tab]);
      return;
    }
    this.router.navigateByUrl('ambiente/agenda/agendamento-cadastro');
  }

  goToAcessoColaboradores() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/employees');

  }

  goToAgendamentoCadastroHorario(tab?: string) {
    this.closeSideBar();
  
    // Força a recarga da rota
    const currentUrl = this.router.url; // URL atual
    const targetUrl = `/ambiente/agenda/agendamento-cadastro/${tab || ''}`;
  
    if (currentUrl === targetUrl) {
      // Redireciona para uma URL "neutra" antes de voltar para a atual
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([targetUrl], { state: { modalSelecionarProfissional: true } });
      });
    } else {
      // Navegação normal se não estiver na mesma rota
      this.router.navigate([targetUrl], { state: { modalSelecionarProfissional: true } });
    }
  }
  
  goToAgendamentoCadastro2(tab?) {
    this.closeSideBar();
    if (tab){
      this.router.navigate(['/ambiente/agenda/agendamento-cadastro/', tab]);
      return;
    }
    this.router.navigateByUrl('ambiente/agenda/agendamento-cadastro');
  }

  goToUserProfile(param: number) {
    this.closeSideBar();
    this.router.navigate([`ambiente/agenda/user-profile/${param}`]);
  }
  

  goToAgendamentoList() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/agendamento-list');
  }

  goToAgendaHistory() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/agenda-history');
  }

  goToFinancial() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/financeiro/1');
  }

  goToFinancial2() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/financeiro/2');
  }

  goToListaEspera() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/lista-espera');
  }

  goToClubeDeAssinaturas() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/clube-de-assinaturas');
  }

  goToPacotes() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/pacotes');
  }

  openExternalLink(linkParam) {
    window.open(linkParam, '_blank');
  }

  openSuporte() {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      window.open("https://wa.me/553591060515?&text=Ol%C3%A1%2C%20preciso%20de%20suporte!", "_blank");
    } else {
      window.open("https://web.whatsapp.com/send?phone=553591060515&text=Ol%C3%A1%2C%20preciso%20de%20suporte!", "blank");
    }

    this.closeSideBar();
  }

  openSideBar() {
    document.getElementById("sidebar").style.left = "0";
    this.globals.showPageMainContent = false;
  }

  closeSideBar(){

    if(this.globals.showPageMainContent || this.router.url.includes('agenda-home')){
      return;
    }

    document.getElementById("sidebar").style.left = "-100%";
    this.globals.showPageMainContent = true;
  }

  goToCores() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/cores');
  }

  isPendingPaymentOneDayTolerance() {
    const assinaturaFimTmp = localStorage.getItem('currentEnd');

    if (assinaturaFimTmp) {
      const assinaturaFimDate = new Date(assinaturaFimTmp);
      assinaturaFimDate.setHours(0,0,0,0);

      const assinaturaFimDatePlusOneDay = assinaturaFimDate.setDate(assinaturaFimDate.getDate() + 1);
      const today = new Date().getTime();
      if (today < assinaturaFimDatePlusOneDay) {
        return true;
      }
    }

    return false;
  }

  private getLink(){
    this.compSvc.getMeusLinks(CompanyUtils.getCompanyId()).pipe(take(1)).subscribe({
      next: (result) => {
        this.company = result[0];
        this.company.img_cartao_link = CompanyUtils.getImgCartaoLink();
      },
      error: () => {
        this.globals.presentToast("Não foi possível trazer o seu link de agendamento");
      }
    });
  }


  private getAgendaCount(agendamentoSvc: AgendamentoService, globals: Globals, alarmTimeOut, agendaCountTimeout, loopAlarm) {

    if(CompanyUtils.getCompanyId() != undefined){
      agendamentoSvc.getCount().subscribe(
        (result) => {

          const currentCount = Number.parseInt(result);

          if (globals.agendaCount == undefined) {
            globals.agendaCount = currentCount;
          }
          else if (currentCount > globals.agendaCount) {
            globals.showAgendaRefreshPrompt = true;
            globals.agendaCount = currentCount;
            if(globals.playAlarm == 1){
              globals.alarm = new Audio('../../assets/audio/ring.mp3');
              globals.alarm.play();
            }
            else if(globals.playAlarm == 2){
              globals.stopAlarm = false;
              loopAlarm(globals, alarmTimeOut, loopAlarm);
            }
          }

          if(globals.checkAgenda){
            agendaCountTimeout = setTimeout(() => {
              this.getAgendaCount(agendamentoSvc, globals, alarmTimeOut, agendaCountTimeout, loopAlarm);
            }, 30000);
          }

        },
        (err) => {
          console.log(err);
        }
      );

    }
    else {
      if(globals.checkAgenda){
        agendaCountTimeout = setTimeout(() => {
          this.getAgendaCount(agendamentoSvc, globals, alarmTimeOut, agendaCountTimeout, loopAlarm);
        }, 30000);
      }
    }
  }

  private loopAlarm(globals: Globals, alarmTimeOut, loopAlarm){

    globals.alarm = new Audio('../../assets/audio/ring.mp3');
    globals.alarm.play();

    alarmTimeOut = setTimeout(() => {
      if(!globals.stopAlarm){
        loopAlarm(globals, alarmTimeOut, loopAlarm);
      }
    }, 5000);

  }

  pauseAlarm(){
    this.globals.stopAlarm = true;
    this.globals.showRefreshPrompt = false;
    this.globals.showAgendaRefreshPrompt = false;
    if(this.globals.alarm != undefined){

      this.globals.alarm.pause();
    }
    clearTimeout(this.alarmTimeOut);
  }

  newAgendaRedirect(){

    if(this.globals.shouldRedirect){
      this.router.navigateByUrl('ambiente/agenda/agendamento-list');
    }

  }

  setSelectedButton(index: number): void {
    this.selectedButton = index;
    localStorage.setItem('selectedButton', index.toString());  // Armazene a seleção no localStorage
  }

  openMenu() {
    this.router.navigate(['ambiente/agenda/products']);
  }

  windowReload() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  public verificarStatus(): void {
    this.mercadoPagoService.checkAssinaturaStatus(this.company_id).subscribe(
      (response: any) => {
        let data = response;
  
        // Se a resposta for uma string, extrai o objeto JSON balanceado
        if (typeof data === 'string') {
          data = data.trim();
  
          const extractJson = (str: string): string | null => {
            const first = str.indexOf('{');
            if (first === -1) return null;
            let count = 0;
            let endIndex = -1;
            for (let i = first; i < str.length; i++) {
              if (str[i] === '{') {
                count++;
              } else if (str[i] === '}') {
                count--;
                if (count === 0) {
                  endIndex = i;
                  break;
                }
              }
            }
            return endIndex !== -1 ? str.substring(first, endIndex + 1) : null;
          };
  
          const jsonStr = extractJson(data);
          if (!jsonStr) {
            console.error("Não foi possível encontrar um objeto JSON válido na resposta:", data);
            return;
          }
          try {
            data = JSON.parse(jsonStr);
          } catch (error) {
            console.error("Erro ao converter resposta para JSON:", error);
            return;
          }
        }
  
        // Se a propriedade "result" existir, usa-a; caso contrário, assume que os dados estão na raiz
        const resultData = data.result ? data.result : data;
  
        // Verifica se há dados de assinatura (ex.: plan_id e value) para decidir qual modal abrir
        if (resultData.status === 'paid') {
          this.paymentStatus = 'paid';
          localStorage.setItem("companyStatusPayment", "paid");
          this.showUpgradeModal(resultData);
  
          if (this.currentSubscription && resultData.day) {
            const planMapping = {
              1: { label: 'Plano Mensal', value: 4990, days: 31 + ' dias' },
              2: { label: 'Plano Semestral', value: 18000, days: 183 + ' dias' },
              3: { label: 'Plano Anual', value: 36000, days: 365 + ' dias' },
              4: { label: 'Plano Vitalício', value: 49700, days: 'Nunca mais pague mensalidades' }
            };
  
            let currentPlan;
            if (this.currentSubscription.plan_id && planMapping[this.currentSubscription.plan_id]) {
              currentPlan = planMapping[this.currentSubscription.plan_id];
            } else {
              currentPlan = { 
                label: 'Desconhecido', 
                value: Number(this.currentSubscription.value) * 100,
                days: 0 
              };
            }
  
            if (currentPlan.days !== 'Nunca mais pague mensalidades') {
              const paymentDate = new Date(resultData.day);
              const expirationDate = new Date(paymentDate.getTime() + currentPlan.days * 24 * 60 * 60 * 1000);
              this.currentSubscription.expirationDate = expirationDate;
            } else {
              this.currentSubscription.expirationDate = 'Nunca mais pague mensalidades';
            }
          }
        } else if (resultData.status === 'unpaid') {
          this.paymentStatus = 'unpaid';
          localStorage.setItem("companyStatusPayment", "unpaid");
          if (resultData.plan_id && resultData.value) {
            this.showUpgradeModal(resultData);
          }
        } else if (resultData.status === 'pending') {
          this.paymentStatus = 'pending';
          localStorage.setItem("companyStatusPayment", "pending");
          if (resultData.plan_id && resultData.value) {
            this.showUpgradeModal(resultData);
          }
        } else if (resultData.plan_id && resultData.value) {
          this.showUpgradeModal(resultData);
        } else {
          this.openPlanoEscolhaModal();
        }
      },
      (error) => {
        console.error("Erro ao verificar status da assinatura:", error);
      }
    );
  }

  public verificarStatusRecorrente(): void {
    this.mercadoPagoService.checkAssinaturaStatus(this.company_id).subscribe(
      (response: any) => {
        let data = response;
  
        // Se a resposta for uma string, extrai o objeto JSON "balanceado"
        if (typeof data === 'string') {
          data = data.trim();
  
          const extractJson = (str: string): string | null => {
            const first = str.indexOf('{');
            if (first === -1) return null;
            let count = 0;
            let endIndex = -1;
            for (let i = first; i < str.length; i++) {
              if (str[i] === '{') {
                count++;
              } else if (str[i] === '}') {
                count--;
                if (count === 0) {
                  endIndex = i;
                  break;
                }
              }
            }
            return endIndex !== -1 ? str.substring(first, endIndex + 1) : null;
          };
  
          const jsonStr = extractJson(data);
          if (!jsonStr) {
            console.error("Não foi possível encontrar um objeto JSON válido na resposta:", data);
            return;
          }
          try {
            data = JSON.parse(jsonStr);
          } catch (error) {
            console.error("Erro ao converter resposta para JSON:", error);
            return;
          }
        }
  
        // Se a propriedade "result" existir, usa-a; caso contrário, assume que os dados estão na raiz
        const resultData = data.result ? data.result : data;
        
        // Executa a lógica para abertura dos modais conforme status e dados do plano
        if (resultData.status === 'paid') {
          this.paymentStatus = 'paid';
          localStorage.setItem("companyStatusPayment", "paid");
        } else if (resultData.status === 'pending') {
          this.paymentStatus = 'pending';
          localStorage.setItem("companyStatusPayment", "pending");
          if (resultData.plan_id && resultData.value) {
            this.showUpgradeModal(resultData);
          }
        } else if (resultData.status === 'unpaid') {
          this.paymentStatus = 'unpaid';
          localStorage.setItem("companyStatusPayment", "unpaid");
          if (resultData.plan_id && resultData.value) {
            this.showUpgradeModal(resultData);
          }
        } else if (resultData.plan_id && resultData.value) {
          this.showUpgradeModal(resultData);
        } else {
          this.openPlanoEscolhaModal();
        }
        
        // Agenda a próxima verificação de acordo com o status retornado
        if (resultData.status === 'paid') {
          // console.log("Status 'paid' - verificação encerrada.");
          // Se estiver "paid", não agendamos nova verificação.
        } else if (resultData.status === 'pending') {
          // console.log("Status 'pending' - agendando nova verificação em 180 segundos.");
          setTimeout(() => {
            this.verificarStatusRecorrente();
          }, 180000); // 180 segundos = 3 minutos
        } else if (resultData.status === 'unpaid') {
          // console.log("Status 'unpaid' - agendando nova verificação em 30 segundos.");
          setTimeout(() => {
            this.verificarStatusRecorrente();
          }, 30000); // 30 segundos
        } else {
          // Caso algum outro status venha, podemos definir um tempo padrão (por exemplo, 60 segundos)
          // console.log("Status desconhecido - agendando nova verificação em 60 segundos.");
          setTimeout(() => {
            this.verificarStatusRecorrente();
          }, 60000);
        }
      },
      (error) => {
        console.error("Erro ao verificar status da assinatura:", error);
        // Em caso de erro, podemos agendar uma nova tentativa após 60 segundos
        setTimeout(() => {
          this.verificarStatusRecorrente();
        }, 60000);
      }
    );
  }
  
  public verificarExpiracaoAssinatura(): void {
    this.mercadoPagoService.checkAssinaturaStatus(this.company_id).subscribe(
      (response: any) => {
        let data = response;
  
        // Se a resposta for uma string, extrai o objeto JSON "balanceado"
        if (typeof data === 'string') {
          data = data.trim();
  
          function extractJson(str: string): string | null {
            const first = str.indexOf('{');
            if (first === -1) return null;
            let count = 0;
            for (let i = first; i < str.length; i++) {
              if (str[i] === '{') {
                count++;
              } else if (str[i] === '}') {
                count--;
              }
              if (count === 0) {
                return str.substring(first, i + 1);
              }
            }
            return null;
          }
  
          const jsonStr = extractJson(data);
          if (!jsonStr) {
            console.error("Não foi possível extrair JSON da resposta:", data);
            return;
          }
          try {
            data = JSON.parse(jsonStr);
          } catch (error) {
            console.error("Erro ao converter resposta para JSON:", error);
            return;
          }
        }
  
        // Se a propriedade "result" não existir, assume-se que os dados já estão no objeto raiz.
        const resultData = data.result ? data.result : data;
  
        // Verifica se existe o campo current_period_end
        const currentPeriodEndStr = resultData.current_period_end;
        if (!currentPeriodEndStr) {
          console.warn('Não existe current_period_end na resposta.', resultData);
          return;
        }
  
        // Captura o status atual (se existir)
        const statusAtual = resultData.status;
  
        // Converte current_period_end para Date (substituindo o espaço por "T" para melhor compatibilidade)
        const currentPeriodEnd = new Date(currentPeriodEndStr.replace(' ', 'T'));
        const agora = new Date();
        const diffMs = currentPeriodEnd.getTime() - agora.getTime();
        const diffDays = diffMs / (1000 * 60 * 60 * 24);
  
        // console.log("currentPeriodEnd:", currentPeriodEnd);
        // console.log("Data atual (agora):", agora);
        // console.log("Diferença em dias (diffDays):", diffDays);
        // console.log("Status atual vindo da API:", statusAtual);
  
        // Define a lógica para atualizar o status:
        // - Se diffDays > 0 => 'paid'
        // - Se diffDays entre 0 e -2 (inclusive) => 'pending'
        // - Se diffDays < -2 => 'unpaid'
        let novoStatus: string;
        if (diffDays > 0) {
          novoStatus = 'paid';
        } else if (diffDays >= -2) {
          novoStatus = 'pending';
        } else {
          novoStatus = 'unpaid';
        }
        
        // console.log("novoStatus calculado:", novoStatus);
  
        // Atualiza o status somente se for diferente do status atual
        if (novoStatus !== statusAtual) {
          this.mercadoPagoService.updateAssinaturaStatus(this.company_id, novoStatus)
            .subscribe({
              next: (resp) => {
                // console.log('Status atualizado no BD com sucesso.', resp);
                setTimeout(() => {
                this.windowReload();
                }, 5000);
              },
              error: (err) => {
                console.error('Falha ao atualizar status no BD', err);
              }
            });
        } else {
          // console.log("O status já está correto, sem necessidade de atualização.");
        }
  
        // Atualiza o localStorage e a variável do componente
        localStorage.setItem('companyStatusPayment', novoStatus);
        this.paymentStatus = novoStatus;
      },
      (error) => {
        console.error('Erro ao verificar status da assinatura:', error);
      }
    );
  }
  
  public verTrialInfo(): void {
    // Consulta os dados do unlock date do backend
    this.companyUnlockSvc.queryUnlockDate().subscribe(
      () => {
        // Processa a lógica com os dados retornados (calcula os dias de trial, a mensagem, etc.)
        this.companyUnlockSvc.processLogic();
  
        // Exibe as informações no console:
        // console.log('Mensagem de encerramento: ' + this.companyUnlockSvc.endOfPeriodMessage);
        
        if (
          this.companyUnlockSvc.companyUnlockDate.date_end_trial &&
          this.companyUnlockSvc.companyUnlockDate.date_end_trial.date
        ) {
          // Converte a string da data em objeto Date
          const trialEndDate = new Date(this.companyUnlockSvc.companyUnlockDate.date_end_trial.date);
          // Cria um objeto Date para o dia atual
          const today = new Date();
  
          // Se a data de término do trial for menor que hoje, o trial terminou
          if (trialEndDate < today) {
            this.assinaturaTrial = false;
          } else {
            this.assinaturaTrial = true;
          }
  
          // console.log('Data de término do Trial: ' + trialEndDate);
          // console.log('Assinatura Trial: ' + this.assinaturaTrial);
        } else {
          // console.log('Data de término do Trial não está definida.');
        }
      },
      error => {
        console.error('Erro ao buscar informações de unlock date:', error);
      }
    );
  }
  
  
 // Método para exibir o modal de upgrade com as opções filtradas
showUpgradeModal(subscriptionData: any): void {
  // Armazena os dados atuais da assinatura
  this.currentSubscription = subscriptionData;
  
  // Mapeamento dos planos disponíveis (valores em centavos)
  const planMapping = {
    1: { label: 'Plano Mensal', value: 4990, days: 31 + ' dias' },
    2: { label: 'Plano Semestral', value: 18000, days: 183 + ' dias' },
    3: { label: 'Plano Anual', value: 36000, days: 365 + ' dias' },
    4: { label: 'Plano Vitalício', value: 49700, days: 'Nunca mais pague mensalidades' }
  };
  

  // Verifica se subscriptionData.plan_id existe e está mapeado; 
  // caso contrário, assume que subscriptionData.value vem em reais e converte para centavos.
  let currentPlan;
  if (subscriptionData.plan_id && planMapping[subscriptionData.plan_id]) {
    currentPlan = planMapping[subscriptionData.plan_id];
  } else {
    currentPlan = { 
      label: 'Desconhecido', 
      value: Number(subscriptionData.value) * 100, // converte de reais para centavos
      days: 0 
    };
  }

  // Armazena o rótulo do plano atual para exibição
  this.currentSubscription.planLabel = currentPlan.label;

  // Filtra apenas os planos com valor superior ao do plano atual.
  this.upgradePlans = [];
  for (const key in planMapping) {
    if (planMapping.hasOwnProperty(key)) {
      const plan = planMapping[key];
      if (plan.value > currentPlan.value) {
        this.upgradePlans.push({
          key: key,
          label: plan.label,
          value: plan.value,
          days: plan.days
        });
      }
    }
  }

  // Ordena do plano mais caro para o mais barato
  this.upgradePlans.sort((a, b) => b.value - a.value);

  this.isUpgradeModalVisible = true;
}

// Método para fechar o modal de upgrade
closeUpgradeModal(): void {
  this.isUpgradeModalVisible = false;
}

selecionarPlano(selectedKey: string): void {
  // Converte a chave numérica (string) para o tipo correto
  let plano: 'mensal' | 'semestral' | 'anual' | 'vitalicio';
  switch(selectedKey) {
    case '1': 
      plano = 'mensal';
      break;
    case '2':
      plano = 'semestral';
      break;
    case '3':
      plano = 'anual';
      break;
    case '4':
      plano = 'vitalicio';
      break;
    default:
      console.error("Plano inválido: " + selectedKey);
      return;
  }
  
  console.log(`Plano selecionado: ${plano}`);
  // Fecha o modal de upgrade (não o de escolha, se for o mesmo modal)
  this.closeUpgradeModal();
  // Gera a cobrança para o plano selecionado
  this.closePlanoEscolhaModal();
  this.closeUpgradeModal();
  this.gerarPixComPlano(plano);
  this.selectedPlano = plano;
}

// Função para gerar pagamento (por Pix ou outro método) de acordo com o plano:
gerarPixComPlano(plano: 'mensal' | 'semestral' | 'anual' | 'vitalicio'): void {
  const planMapping = {
    mensal:    { label: 'Assinatura Mensal',     days: 31,   unit_price: 49.90 },
    semestral: { label: 'Assinatura Semestral',  days: 183,  unit_price: 180.00 },
    anual:     { label: 'Assinatura Anual',      days: 365,  unit_price: 360.00 },
    vitalicio: { label: 'Assinatura Vitalícia',  days: 21900,unit_price: 497.00 }
  };

  const plan = planMapping[plano];
  if (!plan) {
    console.error('Plano inválido:', plano);
    return;
  }

  // Gera identificação única para cada pagamento
  const externalReference = `company_${this.company_id}_${Date.now()}`;

  const payload = {
    items: [
      {
        id: externalReference,
        title: plan.label,
        description: `Pagamento para ${plan.label}`,
        quantity: 1,
        currency_id: 'BRL',
        unit_price: plan.unit_price
      }
    ],
    payer: {
      email: this.emailCompany,
      first_name: this.nomeCompany,
      company_id: this.company_id
    },
    payment_methods: {
      excluded_payment_types: [{ id: 'ticket' }],
      installments: 1
    },
    external_reference: externalReference,
    additional_info: { Dias: plan.days.toString() },
    notification_url: 'https://apiprodt3xl.loopingapp.com.br:7096/api/postback/mercadopago_webhook',
    back_urls: {
      success: 'https://whatsagenda.com.br',
      pending: 'https://seusite.com/pagamento/pendente',
      failure: 'https://seusite.com/pagamento/erro'
    },
    auto_return: 'approved'
  };

  // Faz a requisição para criar a preferência de pagamento
  this.mercadoPagoService.createPreference(payload).subscribe(
    (response) => {
      if (response.init_point) {
        // Abre diretamente o link de pagamento em uma nova aba/janela
        window.open(response.init_point, '_blank');
      } else {
        console.warn('Não foi retornado um init_point para o pagamento.');
      }
    },
    (error) => {
      console.error('Erro ao criar preferência para o plano ' + plano, error);
    }
  );
}

// Função para pagar a assinatura atual (seja qual for o plano configurado):
pagarAssinaturaAtual(): void {
  if (!this.currentSubscription) {
    console.error('Assinatura atual não definida.');
    return;
  }

  const planMapping = {
    1: { label: 'Plano Mensal',     days: 31  },
    2: { label: 'Plano Semestral',  days: 183 },
    3: { label: 'Plano Anual',      days: 365 },
    4: { label: 'Plano Vitalícia',  days: 21900 }
  };

  // Convertendo o valor da assinatura (que pode vir com vírgula) para número
  const subscriptionValue = parseFloat(
    this.currentSubscription.value.toString().replace(',', '.')
  );

  const planInfo = planMapping[this.currentSubscription.plan_id];
  const label = planInfo ? planInfo.label : 'Assinatura Desconhecida';
  const days = planInfo ? planInfo.days : null;

  const externalReference = `company_${this.company_id}_${Date.now()}`;

  const payload: any = {
    items: [
      {
        id: externalReference,
        title: `Pagamento da Assinatura: ${label}`,
        description: `Pagamento da assinatura atual (${label})`,
        quantity: 1,
        currency_id: 'BRL',
        unit_price: subscriptionValue
      }
    ],
    payer: {
      email: this.emailCompany,
      first_name: this.nomeCompany,
      company_id: this.company_id
    },
    payment_methods: {
      excluded_payment_types: [{ id: 'ticket' }],
      installments: 1
    },
    external_reference: externalReference,
    additional_info: days ? { Dias: days.toString() } : null,
    notification_url: 'https://apiprodt3xl.loopingapp.com.br:7096/api/postback/mercadopago_webhook',
    back_urls: {
      success: 'https://whatsagenda.com.br',
      pending: 'https://whatsagenda.com.br/pagamento/pendente',
      failure: 'https://whatsagenda.com.br/pagamento/erro'
    },
    auto_return: 'approved'
  };

  // Faz a requisição para criar a preferência de pagamento
  this.mercadoPagoService.createPreference(payload).subscribe(
    (response) => {
      if (response.init_point) {
        // Abre o link do Mercado Pago (sem plugin) em uma nova aba/janela
        window.open(response.init_point, '_blank');
      } else {
        console.warn('Não foi retornado um init_point para o pagamento.');
      }
      // Se você tiver algum modal de upgrade aberto, pode fechá-lo em seguida
      this.closeUpgradeModal();
    },
    (error) => {
      console.error('Erro ao criar preferência para a assinatura atual', error);
    }
  );
}

  
  closeAgendaLiberadaModal(): void {
    this.isAgendaLiberadaModalVisible = false;
    this.getPurchasedPlan();
    setTimeout(() => {
      this.windowReload();
    }, 5000);
  }

  // Método para abrir o modal de escolha de plano
  openPlanoEscolhaModal(): void {
    this.isPlanoEscolhaModalVisible = true;
  }

  // Método para fechar o modal de escolha de plano
  closePlanoEscolhaModal(): void {
    this.isPlanoEscolhaModalVisible = false;
  }
  
  getAssinaturaFimLabel(): string {
    if (!this.assinaturaFim) {
      return '';
    }
  
    // Supõe que this.assinaturaFim está em formato "DD/MM/YYYY"
    const [diaStr, mesStr, anoStr] = this.assinaturaFim.split('/');
    const dia = parseInt(diaStr, 10);
    const mes = parseInt(mesStr, 10) - 1; // Mês no JS inicia em zero
    const ano = parseInt(anoStr, 10);
  
    const dataFim = new Date(ano, mes, dia);
    if (isNaN(dataFim.getTime())) {
      // Se não conseguir converter para Data, retorna a string original
      return this.assinaturaFim;
    }
  
    // Data atual
    const hoje = new Date();
    // Data de hoje + 10 anos
    const hojeMais10Anos = new Date(hoje.getFullYear() + 10, hoje.getMonth(), hoje.getDate());
  
    // Se dataFim >= hoje + 10 anos, exibe "Vitalício"
    if (dataFim >= hojeMais10Anos) {
      return 'Vitalício';
    }
  
    // Caso contrário, retorna a data normal (já está em DD/MM/YYYY)
    return this.assinaturaFim;
  }

getPurchasedPlan(){
  const company_id = CompanyUtils.getCompanyId();
  return new Promise( (resolve, reject) => {
    this.planSvc.getPurchasedPlanForCompany(company_id).subscribe(
      (result) => {
        // console.log('teste' + JSON.stringify(result));
        this.planAtual = result[0];
        if(result[0].current_period_end){
          localStorage.setItem('currentEnd', result[0].current_period_end.date)
        }
        localStorage.setItem('companyStatusPayment', result[0].status);

        if (this.planAtual) {
          this.getAssinaturaDetailsPagarme();
        }
        resolve(true);
      },
      (err) => {
        this.globals.openDialog('Atenção', 'Não foi possível trazer o plano assinado por esta empresa');
        resolve(false);
      }
    );
  } );
}

getAssinaturaDetailsPagarme(){
  this.assinatSvc.getAssinaturaPagarme(this.planAtual.assinatura_id).subscribe(
    (result) => {
      this.globals.managePaymentUrl = result[0].manage_url;
      

    },
    (err) => {
      // this.globals.openDialog('Atenção', 'Não foi possível conectar ao Pagar.me');
    }
  );
}




}
