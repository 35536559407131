<div class="toast-container">
  <div
    class="toast"
    *ngIf="currentToast"
    [class.toast-success]="currentToast.type === 'success'"
    [class.toast-error]="currentToast.type === 'error'"
    [class.toast-info]="currentToast.type === 'info'"
  >
    <div class="toast-content">
      <span class="toast-icon" [ngClass]="getIcon(currentToast.type)"></span>
      <div class="toast-text">
        <strong class="toast-title">{{ currentToast.type | titlecase }}</strong>
        <p class="toast-message">{{ currentToast.message }}</p>
      </div>
      <button class="toast-close" (click)="closeToast()">×</button>
    </div>
    <div class="toast-progress-bar" [style.width]="progressWidth + '%'"></div>
  </div>
</div>


<!-- Confirm Modal -->
<div class="confirm-modal-overlay" *ngIf="currentConfirm">
  <div class="confirm-modal">
    <div class="confirm-modal-header">
      <h2>Confirmação</h2>
    </div>
    <div class="confirm-modal-body">
      <p>{{ currentConfirm.message }}</p>
    </div>
    <div class="confirm-modal-footer">
      <button class="cancel-button" (click)="cancelAction()">Cancelar</button>
      <button class="confirm-button" (click)="confirmAction()">Confirmar</button>
    </div>
  </div>
</div>

