import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../../../svc/config'; 
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentOpenpixService {
  private apiUrl = `${Config.vBusUrl}company_payment_openpix/`;
  private subaccountUrlBase = `${environment.open_pix_url}api/balance`;
  private withdrawalUrlBase = `${environment.open_pix_url}api/withdrawal`;

  private readonly headers = new HttpHeaders().set('Token', 'QX2ZK-CAAU5-87J9O-JWSWN-8TFJQ');

  constructor(private http: HttpClient) {}

  getCompanyPaymentOpenpix(company_id: string): Observable<any> {
    const httpParams = new HttpParams().set('company_id', company_id);

    return this.http.get(this.apiUrl, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: httpParams
    });
  }

  updatePaymentSettings(companyId: number, settings: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}`, { company_id: companyId, ...settings }, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    });
  }

  getPaymentStatus(companyId: number, uid_payment: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('company_id', companyId.toString())
      .set('uid_payment', uid_payment.toString());

    const url = `${environment.open_pix_url}api/payment/scheduling/${uid_payment}`;

    return this.http.get(url, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: httpParams
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        console.error('Erro ao buscar o status do pagamento:', error);
        throw error;
      })
    );
  }

  getInternalPaymentStatus(companyId: number, uid_payment: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('company_id', companyId.toString())
      .set('uid_payment', uid_payment.toString());
  
    const url = `${Config.vBusUrl}getPaymentStatus/`;
  
    return this.http.get(url, {
      headers: new HttpHeaders(), // Não envia nenhum header (sem token)
      context: new HttpContext()
        .set(NEEDS_COMPANY_BEARER, false)
        .set(NEEDS_USER_BEARER, false),
      params: httpParams
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        console.error('Erro ao buscar o status do pagamento interno:', error);
        throw error;
      })
    );
  }
  


  getPaymentStatusByUid(uid_payment: number): Observable<any> {
    const url = `${environment.open_pix_url}api/payment/scheduling/${uid_payment}`;

    return this.http.get(url, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        throw error;
      })
    );
  }

  getPaymentsByPixKey(pix_key: string): Observable<any> {
    const url = `${environment.open_pix_url}api/payments?pix_key=${pix_key}`;
    const params = new HttpParams().set('pix_key', pix_key);

    return this.http.get(url, {
      headers: this.headers,
      params: params,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
    }).pipe(
      tap((data: any) => data),
      catchError((error) => {
        throw error;
      })
    );
  }

  updatePixKey(oldPixKey: string, newPixKey: string): Observable<any> {
    const url = `${environment.open_pix_url}api/users/subaccount_update`;
    const body = { old_pix_key: oldPixKey, new_pix_key: newPixKey };

    return this.http.post(url, body, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        throw error;
      })
    );
  }

  getSubaccountBalance(pix_key: string): Observable<any> {
    const url = `${this.subaccountUrlBase}`;
    const params = new HttpParams().set('pix_key', pix_key);

    return this.http.get(url, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: params
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        console.error('Erro ao buscar o saldo da subconta:', error);
        throw error;
      })
    );
  }

  requestWithdrawal(pix_key: string, amount: number): Observable<any> {
    const url = `${this.withdrawalUrlBase}`;
    const amountInCents = amount * 100;
    const body = { pix_key: pix_key, amount: amountInCents };

    return this.http.post(url, body, {
      headers: this.headers,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => data),
      catchError((error) => {
        console.error('Erro ao solicitar o saque:', error);
        throw error;
      })
    );
  }

  getWithdrawalHistory(pix_payment_key: string): Observable<any> {
    const url = `${environment.open_pix_url}api/withdrawals`;
    const params = new HttpParams().set('pix_key', pix_payment_key);

    return this.http.get(url, {
      headers: this.headers,
      params: params,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => data.map((item: any) => ({
        id: item.id,
        updated_at: item.updated_at,
        value: item.value / 100,
        status: item.status
      }))),
      catchError((error) => {
        console.error('Erro ao buscar o histórico de saques:', error);
        throw error;
      })
    );
  }
}
