export const environment = {
  production: true,
  prodFlag: 0,
  // CONFIG_API_URL: 'https://apiprod.loopingapp.com.br:7096/',
  CONFIG_API_URL: 'https://apiprodt3xl.loopingapp.com.br:7096/',
  cadastro_config_url: 'https://apiprodt3xl.loopingapp.com.br:7096/api/v_app/',
  open_pix_url: "https://gateway.pandoapps.com.br/",
  OPENPIX_CLIENT_ID: 'af9b7356-370a-49c0-8c36-10461bf1de78',
  OPENPIX_AUTH_TOKEN: 'Q2xpZW50X0lkX2FmOWI3MzU2LTM3MGEtNDljMC04YzM2LTEwNDYxYmYxZGU3ODpDbGllbnRfU2VjcmV0X1ZEMzZZemVtT2JabXZOMmVUM3dBMWE1RklLRUVxdE1UNVFTd1FzSEVMUmc9'
};
