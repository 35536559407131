import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Config } from '../../config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CompanyUtils } from '../company-utils';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaService extends BaseService {
  baseURL = `${Config.vBusUrl}criar_assinatura202105/`;
  upDownURL = `${Config.vBusUrl}change_assinatura_edp/`;
  cancelURL = `${Config.vBusUrl}cancel_assinatura_edp/`;
  assinaturaDetPagarme = `${Config.vBusUrl}get_assinatura_details_pagarme/`;
  assinatAndDetails = `${Config.vBusUrl}get_assinatura_e_details/`;
  assinatPopup = `${Config.vBusUrl}get_assinatura_info_popup/`;
  extendURL = `${Config.vBusUrl}extend_assinatura_edp/`;


  constructor(private h: HttpClient) {
    super(h);
  }

  getPlanId(id){
    return this.http.get(this.baseURL + id + "/-2")
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getAssinaturaPagarme(id){
    return this.http.get(this.assinaturaDetPagarme + `${CompanyUtils.getCompanyId()}/` + id )
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getAssinaturaEDetails(){
    return this.http.get(this.assinatAndDetails + `${CompanyUtils.getCompanyId()}`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getAssinaturaPopup(id){
    return this.http.get(this.assinatPopup + `${CompanyUtils.getCompanyId()}/${id}`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  handleErrors(error: HttpErrorResponse) {
    console.log("APP LOG ERROR: " + error);
    this.objErro = error;
    if (this.objErro.status == 0) {
      return throwError("Houve uma queda temporária de conexão entre este dispositivo e o nosso servidor. Por favor tente novamente em breve.");
    } 
    // else if (error.status == 400) {
    //   return throwError(this.objErro.error.message);
    // }
    return throwError(error);
  }

  criarAssinatura(pObj){
      const obj = JSON.stringify(pObj);
      return this.http.post(this.baseURL, obj)
      .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  changeAssinatura(pObj){
    const obj = JSON.stringify(pObj);
      return this.http.post(this.upDownURL, obj)
      .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  cancelAssinatura(pObj){
    const obj = JSON.stringify(pObj);
      return this.http.post(this.cancelURL, obj)
      .pipe(
        map((data: any) => {
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  extendAssinatura(pObj: any) {
    const obj = JSON.stringify(pObj);
    return this.http.post(this.extendURL, obj)
      .pipe(
        map((data: any) => {
          if (data.status !== '200') {
            // chamando manualmente o catchError
            catchError(this.handleErrors);
          }
          return data; // ou data.result - depende de como quer tratar
        }),
        catchError(this.handleErrors)
      );
  }
  

  

}
