import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentOpenpixQrcodeService {
  private baseUrl = `${environment.open_pix_url}api/payment`; // URL base para a requisição

  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Token': 'QX2ZK-CAAU5-87J9O-JWSWN-8TFJQ'
  });

  constructor(private http: HttpClient) {}

  // Função para enviar a requisição de pagamento
  makePaymentRequest(pix_key_recipient: string, value: number, payment_external_id: number): Observable<any> {
    const payload = {
      pix_key_recipient: pix_key_recipient,
      value: value,  // Valor já em centavos
      payment_external_id: payment_external_id  // Deve ser um número inteiro
    };

    return this.http.post(this.baseUrl, payload, { headers: this.headers }).pipe(
      map((data: any) => data),
      catchError((error) => {
        console.error('Erro na requisição de pagamento:', error);
        throw error;
      })
    );
  }
}
