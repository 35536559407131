import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {
  // Endpoint para criar checkout preferences
  private baseURL = 'https://api.mercadopago.com/checkout/preferences';

  baseURLapi = `${Config.vBusUrl}assinatura_status/`;
  updateStatusURL = `${Config.vBusUrl}assinatura_update/`;
  // Substitua pelo seu access token do Mercado Pago
  private accessToken = 'APP_USR-4916536856276134-022709-825fbcb9082978ab99dec115d66cb2fa-2293739500';

  constructor(private http: HttpClient) { }

  /**
   * Cria uma preferência de pagamento no Mercado Pago.
   * @param payload - Objeto com os dados da preferência.
   */
  createPreference(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.accessToken}`
    });
    return this.http.post(this.baseURL, payload, { headers });
  }

  checkAssinaturaStatus(company_id: string): Observable<any> {
    const urlBack = `${this.baseURLapi}?company_id=${company_id}`;
    return this.http.get<any>(urlBack);
  }

  updateAssinaturaStatus(company_id: string, newStatus: string): Observable<any> {
    const payload = { company_id, status: newStatus };
    return this.http.post<any>(this.updateStatusURL, payload, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
}
