import { Workhour } from "../workhour/workhour";
import { PaymentMethod } from '../payment-method/payment-method';
import { CompanyUnlockDate } from "./comp-unlock-date/company-unlock-date";

export class Company {
  public company_id: number;
  public razao_social: string;
  public nome_fantasia: string;
  public nome_responsavel: string;
  public address_complete: string;
  public state_name: string;
  public city_name: string;
  public category_id: number;
  public category_id_2: number;
  public phone: string;
  public receive_whatsapp_msg: boolean;
  public whatsapp_phone: string;
  public email: string;
  public img_cartao_link: string;
  public img_banner_link: string;
  public description: string;
  public frete_dist_maior: number;
  public fb_mess_link: string;
  public latitude: number;
  public longitude: number;
  public hasCartaoImg: boolean;
  public hasTicket: number;
  public hasCard: boolean;
  public categoria_name: string;
  public workhours: Array<Workhour>;
  public prodCount: number;
  public companyPayment: PaymentMethod[] = [];
  public frete: number;
  public handle: string;
  public frete_mode_other_locations: number;
  public sends_whats_message: boolean;
  public tempo_abertura: number; // dias de tolerancia de abertura de agenda
  public blocked_by_payment: boolean;
  public company_unlock_date: CompanyUnlockDate;
  public tempo_agendar_antecedencia: number;
  public campo_flexivel_label: string;
  public is_campo_flexivel_mandatory: boolean;
  public is_login_mandatory: boolean;
  public pix_enable: boolean;
  public pix_info: string;
  public price_perc: number;
  public lista_espera_enable: boolean;
  public linkThemeName: string;
  public iso4217_code: string;
  public currency_symbol: string;
  public unicodeLocaleId: string;
  public country_id: number;
  public city_id: number;
  public state_id: number;

  // Novas propriedades para integração de pagamento
  public accept_mandatory_customer_payment: boolean;
  public accept_payment_fees: boolean;
  public accept_refund_without_fees: boolean;
  public accept_customer_request_refund: boolean;
  public pix_payment_key: string;
  public payment_fixed: boolean;
  public payment_percentage: boolean;
  public percentage_value_payment: string;
  public accept_sign_and_value_total: boolean;
  public accept_value_total: boolean;
  public payment_integration_completed: boolean;
  public payment_status_encounter: string;
  public first_integration: boolean;
}
