<ngx-ui-loader></ngx-ui-loader>

<div class="mainBackground" [class]="[currentTheme]">

  <div class="topBlueBackground" [class]="[currentTheme]"> </div>


    <!-- ############################################################ -->
    <!--                            Topbar                            -->
    <!-- ############################################################ -->

    <app-company-top-bar (menuBtnClicked)="closeSideBar()"></app-company-top-bar>

    <app-subscription-feedback [triggerInputAux]="triggerBool"></app-subscription-feedback>

    <div class="cursorPointer marginHorizontalSmall marginVerticalMedium position-absolute top-0 start-0"
            *ngIf="globals.showMenuBtn && globals.showPageMainContent && globals.showTopBar" (click)="openSideBar()">

        <i class="fa fa-bars textBig mainColorPrimary200" aria-hidden="true"></i>

    </div>

    <!-- <div class="autoLayout align-items-start w-100"> -->
    <div class="d-flex align-items-start">
        <!-- ############################################################ -->
        <!--                            Sidebar                           -->
        <!-- ############################################################ -->


        <div class="sideBar" id="sidebar" [ngStyle]="{'left' : ((globals.showPageMainContent && globals.showMenuBtn) || !globals.showTopBar ? '-100%' : '0')}">

            <div class="flex-column" style="margin-left: 4%;">

                <div class="imgNameCompany">

                    <img src="{{company.img_cartao_link}}" class="companyLogo" alt="">

                    <div class="companyInfoDiv">
                        <label class="companyName">Olá,<br>{{nomeCompany}}</label>
                    </div>

                </div>
                <p class="companyName2">O link da sua agenda digital é:</p>
                <div class="div-link">
                  <div class="btn-copy-border">
                    <!-- Link da Empresa -->
                    <a class="linkAgd" [href]="company.handle" target="_blank">{{ company.handle }}</a>
                  </div>
                  <button 
                      class="btn-copy" 
                      ngxClipboard 
                      [cbContent]="company.handle" 
                      (click)="linkCopied = true">
                      <i *ngIf="!linkCopied" class="fa fa-files-o" aria-hidden="true"></i>
                      <i *ngIf="linkCopied" class="fa fa-check" aria-hidden="true"></i>
                    </button>
                </div>
                

            </div>

            <div class="menu lateral autoLayout flex-column">

              <!-- Menu Lateral -->
              <div class="side-menu">
                
                <!-- 1. Minha agenda -->
                <div class="menuGroupHeader" (click)="toggleMinhaAgenda(); setSelectedButton(1)">
                  <span class="groupLabel">
                    <i class="fa" [ngClass]="{'fa-chevron-down': isVerAgendaExpanded, 'fa-chevron-right': !isVerAgendaExpanded}" aria-hidden="true"></i>
                    Minha agenda
                  </span>
                </div>
                <div *ngIf="isVerAgendaExpanded">
                  <div class="menuGroup">
                    <div class="iconDivSub subItem" (click)="goToAgendamentoList();" [ngClass]="{'selected': selectedButton === 1}">
                      <span class="iconLabelSub">
                        <i class="fa fa-calendar" aria-hidden="true"></i> Ver calendário
                      </span>
                    </div>
                    <!-- Lista de Espera -->
                    <div *ngIf="listaEspera" class="iconDivSub subItem" (click)="navigateAndOpenModal('esperaModal1', 20)" [ngClass]="{'selected': selectedButton === 20}">
                      <span class="iconLabelSub">
                        <i class="fa fa-th-list" aria-hidden="true"></i>
                        Lista de Espera
                        <span class="badge" *ngIf="totalEspera > 0">{{ totalEspera }}</span>
                      </span>
                    </div>
                    <!-- Confirmar Horários -->
                    <div class="iconDivSub subItem" (click)="navigateAndOpenModal('confirmarModal1')" [ngClass]="{'selected': selectedButton === 21}">
                      <span class="iconLabelSub">
                        <i class="fa fa-check-circle" style="color: green;"></i>
                        Confirmar Horários
                      </span>
                    </div>
                    <!-- Bloquear Horários -->
                    <div class="iconDivSub subItem" (click)="navigateAndOpenModal('recessoModal1')" [ngClass]="{'selected': selectedButton === 22}">
                      <span class="iconLabelSub">
                        <i class="fa fa-lock" style="color: red;"></i>
                        Bloquear Horários 
                        <span *ngIf="blockCount > 0" class="badge">{{ blockCount }}</span>
                      </span>
                    </div>
                    <!-- Histórico de agendamentos -->
                    <div class="iconDivSub subItem" (click)="goToAgendaHistory(); setSelectedButton(2)" [ngClass]="{'selected': selectedButton === 2}" *ngIf="historicoAgenda">
                      <span class="iconLabelSub">
                        <i class="fa fa-history" aria-hidden="true"></i> Histórico de agendamentos
                      </span>
                    </div>
                  </div>
                </div>
                
                <!-- 3. Cadastros da agenda -->
                <div class="menuGroupHeader" (click)="toggleCadastrosAgenda()">
                  <span class="groupLabel">
                    <i class="fa" [ngClass]="{'fa-chevron-down': isCadastrosAgendaExpanded, 'fa-chevron-right': !isCadastrosAgendaExpanded}" aria-hidden="true"></i>
                    Cadastros da agenda
                  </span>
                </div>
                <div *ngIf="isCadastrosAgendaExpanded">
                  <!-- Serviços -->
                  <div *ngIf="editarAgenda" class="iconDivSub subItem" (click)="goToAgendamentoCadastro2(3); setSelectedButton(3)" [ngClass]="{'selected': selectedButton === 3}">
                    <span class="iconLabelSub">
                      <i class="fa fa-briefcase" aria-hidden="true"></i> Serviços
                    </span>
                  </div>
                  <!-- Profissionais -->
                  <div *ngIf="editarAgenda" class="iconDivSub subItem" (click)="goToAgendamentoCadastro(1); setSelectedButton(4)" [ngClass]="{'selected': selectedButton === 4}">
                    <span class="iconLabelSub">
                      <i class="fa fa-user" aria-hidden="true"></i> Profissionais
                    </span>
                  </div>
                  <!-- Horários de atendimento -->
                  <div *ngIf="editarAgenda" class="iconDivSub subItem" (click)="goToAgendamentoCadastroHorario(); setSelectedButton(5)" [ngClass]="{'selected': selectedButton === 5}">
                    <span class="iconLabelSub">
                      <i class="fa fa-clock" aria-hidden="true"></i> Horários de atendimento
                    </span>
                  </div>
                  <!-- Acesso de colaboradores -->
                  <div *ngIf="gerenciarFuncionarios" class="iconDivSub subItem" (click)="goToAcessoColaboradores(6); setSelectedButton(6)" [ngClass]="{'selected': selectedButton === 6}">
                    <span class="iconLabelSub">
                      <i class="fa fa-user-plus" aria-hidden="true"></i> Acesso de colaboradores
                    </span>
                  </div>
                </div>
                
                <!-- 5. Financeiro -->
                <div class="menuGroupHeader" (click)="toggleFinanceiro()">
                  <span class="groupLabel">
                    <i class="fa" [ngClass]="{'fa-chevron-down': isFinanceiroExpanded, 'fa-chevron-right': !isFinanceiroExpanded}" aria-hidden="true"></i>
                    Financeiro
                  </span>
                </div>
                <div *ngIf="isFinanceiroExpanded">
                  <!-- Controle de Caixa -->
                  <div *ngIf="controledecaixa" class="iconDivSub subItem" (click)="setSelectedButton(16); goToCaixa()" [ngClass]="{'selected': selectedButton === 16}">
                    <span class="iconLabelSub">
                      <i class="fa fa-fax" aria-hidden="true"></i> Controle de Caixa
                      <span *ngIf="isCashRegisterOpen" class="status-indicator open"></span>
                      <span *ngIf="!isCashRegisterOpen" class="status-indicator closed"></span>
                    </span>
                  </div>
                  <!-- Comandas -->
                  <div *ngIf="comandas" class="iconDivSub subItem" (click)="setSelectedButton(17); goToComandas()" [ngClass]="{'selected': selectedButton === 17}">
                    <span class="iconLabelSub">
                      <i class="fa fa-id-card" aria-hidden="true"></i> Comandas
                    </span>
                  </div>
                  <!-- Cálculo de comissões -->
                  <div class="iconDivSub subItem" (click)="goToFinancial(); setSelectedButton(8)" [ngClass]="{'selected': selectedButton === 8}" *ngIf="financeiro">
                    <span class="iconLabelSub">
                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i> Cálculo de comissões
                    </span>
                  </div>
                  <!-- Relatório de faturamento -->
                  <div class="iconDivSub subItem" (click)="goToDashboard(); setSelectedButton(9)" [ngClass]="{'selected': selectedButton === 9}" *ngIf="relatoriosFaturamento">
                    <span class="iconLabelSub">
                      <i class="fa fa-chart-simple" aria-hidden="true"></i> Relatório de faturamento
                    </span>
                  </div>
                  <!-- Integração de pagamento -->
                  <div class="iconDivSub subItem" (click)="setSelectedButton(15); goToIntegracaoPagamento()" [ngClass]="{'selected': selectedButton === 15}" *ngIf="isAdmin">
                    <span class="iconLabelSub">
                      <i class="fa fa-qrcode" aria-hidden="true"></i> Pagamentos
                    </span>
                  </div>
                </div>

                <!-- Configurações da agenda -->
                <div class="menuGroupHeader" (click)="toggleConfigAgenda()">
                  <span class="groupLabel">
                    <i class="fa" [ngClass]="{'fa-chevron-down': isConfigAgendaExpanded, 'fa-chevron-right': !isConfigAgendaExpanded}" aria-hidden="true"></i>
                    Configurações Gerais
                  </span>
                </div>
                <div *ngIf="isConfigAgendaExpanded">
                  <!-- Meu Perfil -->
                  <div class="iconDivSub subItem" (click)="goToUserProfile(1); setSelectedButton(23)" [ngClass]="{'selected': selectedButton === 23}">
                    <span class="iconLabelSub">
                      <i class="fa fa-shop" aria-hidden="true"></i> Perfil do negócio
                    </span>
                  </div>
                  <!-- Agendamento -->
                  <div class="iconDivSub subItem" (click)="goToUserProfile(2); setSelectedButton(24)" [ngClass]="{'selected': selectedButton === 24}">
                    <span class="iconLabelSub">
                      <i class="fa fa-cogs" aria-hidden="true"></i> Regras de agendamento
                    </span>
                  </div>
                  <!-- Pagamento -->
                  <div class="iconDivSub subItem" (click)="goToUserProfile(3); setSelectedButton(25)" [ngClass]="{'selected': selectedButton === 25}">
                    <span class="iconLabelSub">
                      <i class="fa fa-credit-card-alt" aria-hidden="true"></i> Métodos de pagamento
                    </span>
                  </div>
                  <!-- Personalização -->
                  <div class="iconDivSub subItem" (click)="goToUserProfile(4); setSelectedButton(26)" [ngClass]="{'selected': selectedButton === 26}">
                    <span class="iconLabelSub">
                      <i class="fa fa-paint-brush" aria-hidden="true"></i> Escolha suas cores
                    </span>
                  </div>
                </div>
                
                <!-- 4. Clientes e prontuário -->
                <div class="iconDiv" (click)="goToClientes(); setSelectedButton(10)" [ngClass]="{'selected': selectedButton === 10}" *ngIf="clientes">
                  <span class="iconLabel">
                    <i class="fa fa-users" aria-hidden="true"></i> Clientes e prontuário
                  </span>
                </div>
                
                <!-- 6. Clube de Assinaturas -->
                <div class="iconDiv" (click)="setSelectedButton(18); goToClubeAssinaturas()" [ngClass]="{'selected': selectedButton === 18}">
                  <span class="iconLabel">
                    <span class="iconLabelGem">
                      <i class="fa fa-gem" aria-hidden="true"></i>
                    </span>
                    Clube de Assinaturas
                  </span>
                </div>
                
                <!-- 7. Produtos e Estoque -->
                <div *ngIf="produtoseestoque" class="iconDiv" (click)="setSelectedButton(19); goToProdutos()" [ngClass]="{'selected': selectedButton === 19}">
                  <span class="iconLabel">
                    <i class="fa fa-shopping-basket" aria-hidden="true"></i> Produtos e Estoque
                  </span>
                </div>
                
                
                
                <!-- 9. Como funciona o WhatsAgenda -->
                <div class="iconDiv" (click)="setSelectedButton(14); goToMeuLink()" [ngClass]="{'selected': selectedButton === 14}" *ngIf="isAdmin">
                  <span class="iconLabel">
                    <i class="fa fa-question" aria-hidden="true"></i> Como usar
                  </span>
                </div>
                
              </div>
            </div>
            

<!-- Modal de Escolha de Plano -->
<div
  *ngIf=""
  id="planoEscolhaModal"
  class="modal-overlay"
  (click)="closePlanoEscolhaModal()"
>
  <div class="modal-content animated zoomIn" (click)="$event.stopPropagation()">
    <div class="modal-header plano-header">
      <h3 class="modal-title">Assinatura Expirada!</h3>
    </div>
    <div class="modal-body">
      <p>
        Sua assinatura (mensal, semestral ou anual) está expirada.<br />
        Escolha uma nova assinatura abaixo para regularizar e liberar sua agenda:
      </p>
      <div class="plano-buttons">
        <button class="btn plano-btn plano-mensal" (click)="selecionarPlano('mensal')">
          <span class="plano-nome">Plano Mensal</span><br />
          <small>(31 dias)</small><br />
          <span class="plano-preco">R$ 49,90</span>
        </button>
        <button class="btn plano-btn plano-semestral" (click)="selecionarPlano('semestral')">
          <span class="plano-nome">Plano Semestral</span><br />
          <small>(181 dias)</small><br />
          <span class="plano-preco">R$ 149,90</span>
        </button>
        <button class="btn plano-btn plano-anual" (click)="selecionarPlano('anual')">
          <span class="plano-nome">Plano Anual</span><br />
          <small>(366 dias)</small><br />
          <span class="plano-preco"><s>R$ 359,90</s> R$ 279,90</span>
          <span class="promo-badge">PROMOÇÃO</span>
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="closePlanoEscolhaModal()" class="btn-close-qr">Cancelar</button>
    </div>
  </div>
</div>

<!-- Modal de Upgrade de Assinatura -->
<div *ngIf="isUpgradeModalVisible" class="mp-modal-overlay" (click)="closeUpgradeModal()">
  <div class="mp-modal-content" (click)="$event.stopPropagation()">
    <!-- Cabeçalho -->
    <div class="mp-modal-header">
      <strong class="mp-modal-title">Realize o pagamento</strong>
      <button class="mp-close-button" (click)="closeUpgradeModal()"></button>
    </div>

    <!-- Subtítulo ou destaque -->
    <strong class="mp-modal-subtitle">Pague por PIX e libere imediatamente</strong>

    <!-- Corpo do modal -->
    <div class="mp-modal-body">
      <p>
        Seu plano atual é de 
        <strong>{{ currentSubscription?.value | currency:'BRL':'symbol':'1.2-2' }}</strong>.
      </p>

      <!-- Pagamento da assinatura atual -->
      <div class="mp-plano-item" (click)="pagarAssinaturaAtual()">
        <p>Pague sua assinatura atual:</p>
        <p>
          Valor: 
          <strong>{{ currentSubscription?.value | currency:'BRL':'symbol':'1.2-2' }}</strong>
        </p>
        <span class="mp-arrow">></span>
      </div>

      <p>Dê um UPGRADE em seu plano e economize:</p>

      <!-- Lista de planos -->
      <div *ngFor="let plan of upgradePlans" class="mp-plano-item" (click)="selecionarPlano(plan.key)">
        <div class="section-name">
          <strong class="mp-plano-nome">{{ plan.label }}</strong>
          <small> {{ plan.days }}</small>
        </div>
        <div>
          <span>{{ (plan.value / 100) | currency:'BRL':'symbol':'1.2-2' }}</span>
          <span class="mp-arrow">></span>
        </div>
      </div>

      <!-- Pagamento com cartão -->
      <div class="mp-plano-item" (click)="goToUsagePlans()">
        <strong class="mp-plano-nome">Pagar com cartão de crédito</strong>
        <span class="mp-arrow">></span>
      </div>
    </div>
  </div>
</div>



<!-- Modal de Escolha de Plano -->
<div *ngIf="isPlanoEscolhaModalVisible" class="custom-modal-overlay" (click)="closePlanoEscolhaModal()">
  <div class="custom-modal-content" (click)="$event.stopPropagation()">
    <div class="header-modal-plan">
      <h3 class="title-modal-plan">Para continuar utilizando o whatsagenda, escolha um de nossos planos.</h3>
      <button class="close-button-global" (click)="closePlanoEscolhaModal()"></button>
    </div>

    <div class="custom-modal-body">
    
      <!-- Plano Vitalício com tag de promoção -->
      <div class="custom-plano-item custom-destaque custom-especial" (click)="selecionarPlano('4')">
        <!-- Tag de promoção -->
        <div class="promo-tag">PROMOÇÃO</div>
        
        <div class="custom-plano-destaque">
          <img src="assets/icons/premio.png" alt="Ícone prêmio" class="custom-icon" />
          <span>Preferido entre os clientes (oferta limitada)</span>
        </div>
        <strong class="custom-plano-nome">PLANO VITALÍCIO:</strong> R$497,00<br />
        <span class="custom-plano-pagamento">PAGAR POR PIX</span><br />
        <small class="custom-plano-desc">(libera agenda por <b>60 anos</b>)</small>
        <span class="custom-arrow">></span>
      </div>

      <!-- Plano Anual -->
      <div class="custom-plano-item custom-destaque" (click)="selecionarPlano('3')">
        <div class="custom-plano-destaque">
          <img src="assets/icons/economia.png" alt="Ícone economia" class="custom-icon" />
          <span>Economia ao longo do ano</span>
        </div>
        <strong class="custom-plano-nome">PLANO ANUAL:</strong> R$360,00<br />
        <span class="custom-plano-pagamento">PAGAR POR PIX</span><br />
        <small class="custom-plano-desc">(libera agenda por 365 dias)</small>
        <span class="custom-arrow">></span>
      </div>

      <!-- Plano Semestral -->
      <div class="custom-plano-item" (click)="selecionarPlano('2')">
        <strong class="custom-plano-nome">PLANO SEMESTRAL:</strong> R$180,00<br />
        <span class="custom-plano-pagamento">PAGAR POR PIX</span><br />
        <small class="custom-plano-desc">(libera agenda por 183 dias)</small>
        <span class="custom-arrow">></span>
      </div>

      <!-- Plano Mensal -->
      <div class="custom-plano-item" (click)="selecionarPlano('1')">
        <strong class="custom-plano-nome">PLANO MENSAL:</strong> R$49,90<br />
        <span class="custom-plano-pagamento">PAGAR POR PIX</span><br />
        <small class="custom-plano-desc">(libera agenda por 31 dias)</small>
        <span class="custom-arrow">></span>
      </div>

    </div>
  </div>
</div>


<!-- Modal para agenda liberada -->
<div *ngIf="isAgendaLiberadaModalVisible" id="agendaLiberadaModal" class="modal-overlay" (click)="closeAgendaLiberadaModal()">
  <div class="modal-content animated fadeInDown" (click)="$event.stopPropagation()">
    <div class="modal-header liberada-header">
      <h3 class="modal-title">Parabéns!</h3>
    </div>
    <div class="modal-body">
      <p>
        Sua agenda foi liberada com sucesso!<br>
        Agora você pode continuar utilizando todos os recursos premium do sistema.
      </p>
    </div>
    <div class="modal-footer">
      <button (click)="closeAgendaLiberadaModal()" class="btn-close-qr">Fechar</button>
    </div>
  </div>
</div>




<div *ngIf="cardsLoaded" class="assinatura-container">
  
  <!-- Se NULL e está em período de trial -->
  <div
    *ngIf="(companyPaymentStatus === 'null' || !companyPaymentStatus && assinaturaTrial)"
    class="assinatura-card"
  >
    <h5 class="txt-supp">Você ainda não tem um plano assinado</h5>
    <span class="txt-planos">
      Para usar o WhatsAgenda, ESCOLHA UM PLANO
    </span>
    <button
      class="btn-planos"
      (click)="goToCreatePlan()"
      *ngIf="planosAssinaturas"
    >
      PLANOS E PREÇOS
    </button>
  </div>

  <!-- Se NULL e já acabou trial (após trial) -->
  <div
    *ngIf="(companyPaymentStatus === 'null' || !companyPaymentStatus && !assinaturaTrial)"
    class="assinatura-card"
  >
    <img src="assets/ALERTNOPAY.png" class="imgalert" alt="Alerta" />
    <h5 class="txt-supp">AGENDA BLOQUEADA!!!</h5>
    <span class="txt-planos">Necessário escolher um plano</span>
    <span class="txt-planos">
      Você ainda não tem um plano assinado.<br />
      Para usar o WhatsAgenda, ESCOLHA UM PLANO
    </span>
    <button
      class="btn-planos"
      (click)="goToCreatePlan()"
      *ngIf="planosAssinaturas"
    >
      PLANOS E PREÇOS
    </button>
  </div>

  <!-- Se PENDENTE ou UNPAID -->
  <div
    *ngIf="companyPaymentStatus === 'pending' || companyPaymentStatus === 'unpaid'"
    class="assinatura-card"
  >
    <img src="assets/ALERTNOPAY.png" class="imgalert" alt="Alerta" />
    <h5 class="txt-supp">AGENDA BLOQUEADA!!!</h5>
    <span class="txt-planos">Necessário realizar renovação</span>
    <span class="txt-planos">
      Sua agenda ficou ativa até: <br>
      📅{{getAssinaturaFimLabel()}}
    </span>
    <span class="txt-planos">
      Para continuar usando sem interrupções, você pode renovar antecipadamente
    </span>
    <button
      class="btn-planos"
      (click)="verificarStatus()"
      *ngIf="planosAssinaturas"
    >
      Renovar Agora
    </button>
  </div>

  <!-- Se PAID -->
  <div *ngIf="companyPaymentStatus === 'paid'" class="assinatura-card">
    <span class="txt-planos">
      Sua agenda está ativa até: <br>
      📅{{getAssinaturaFimLabel()}}
    </span>
    <button
      class="btn-planos1"
      (click)="verificarStatus()"
      *ngIf="planosAssinaturas && isLessThan90Days()"
    >
      Estender Acesso
    </button>
  </div>

</div>


  <div class="assinatura2">
    <h5 class="txt-supp">Suporte</h5>
    <span class="txt-suporte">Fale pelo WhatsApp com nosso suporte. Horário comercial.</span>
    <button class="btn-suporte" (click)="toggleSupportModal()">Falar com Suporte</button>
  </div>

  <!-- Botão para abrir o modal de escolha de plano (apenas para teste) -->
  <button (click)="verificarStatus()" class="btn-open-plano">Escolher Plano</button>

</div>


<!-- Modal de Suporte -->
<div *ngIf="isSupportModalVisible" id="supportModal" class="support-modal" (click)="toggleSupportModal()">
  <div class="support-modal-content animate-modal" (click)="$event.stopPropagation()">
    <div class="header-suporte">
      <h3 class="modal-title">TIRE AS SUAS DÚVIDAS PELOS NOSSOS CANAIS</h3>
      <button class="close-button-global" (click)="toggleSupportModal()"></button>
    </div>   
    <p class="modal-description">Regularize o seu pagamento</p>
    <button (click)="verificarStatus(); toggleSupportModal()" class="btn payment-btn animate-pulse">PAGAR MEU PLANO</button>

    <p class="modal-description">Dúvidas frequentes:</p>
    <a href="https://www.youtube.com/watch?v=4ldbthkf6Dc&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=13" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Horário de atendimento</button>
    </a>
    <a href="https://www.youtube.com/watch?v=pPcw9fVynH4&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=8" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Bloquear horário</button>
    </a>
    <a href="https://www.youtube.com/watch?v=Vv57mf00eOw&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=10" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Valor e tempo dos serviços</button>
    </a>
    <a href="https://www.youtube.com/watch?v=VWVfZvhUT40&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=11" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Cadastrar profissionais</button>
    </a>
    <a href="https://www.youtube.com/watch?v=z1ufMjtqmzA&list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8&index=15" target="_blank" style="text-decoration: none;">
      <button class="btn frequent-question-btn">Criar acesso para profissionais</button>
    </a>

    <p class="modal-description">
      Sua dúvida não é referente aos itens acima? Veja outros tutoriais completos.
    </p>
    <a href="https://www.youtube.com/playlist?list=PLzJp-jtH3Gpti9pKLSOWXpyOxqAEI74V8" target="_blank" style="text-decoration: none;">
      <button class="btn youtube-btn animate-button">
        <i class="fa fa-youtube-play"></i> YOUTUBE
      </button>
    </a>

    <p class="modal-description">Ou fale com nosso time de suporte:</p>
    <button (click)="openSuporte()" class="btn support-btn">FALAR NO WHATSAPP</button>

    <p class="support-hours">Atendimento de segunda a sexta das 08:00 às 17:00.</p>
  </div>
</div>



        <!-- ############################################################ -->
        <!--                              Page                            -->
        <!-- ############################################################ -->

        <div class="mainContent" id="pageContent">
            <!-- <div class="w-100" *ngIf="globals.showPageMainContent"> -->
              <div *ngIf="globals.showPageMainContent">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>


<!-- -----------------------------------------------------------------------  -->
<!-- Agenda Refresh prompt -->
<!-- -----------------------------------------------------------------------  -->

<!-- <div class="refreshPrompt" [@refreshPromptAnimation] *ngIf="globals.showAgendaRefreshPrompt">

    <label style="font-weight: 600; color: white; margin-top: 2%;">Existem novos agendamentos no sistema, deseja ir para a agenda?</label>

    <div style="display: flex; justify-content: space-evenly; margin-top: 5%;">
      <button class="jumpBtn" style="display: inline-block;" (click)="pauseAlarm(); newAgendaRedirect()">Sim</button>
      <button class="jumpBtn" style="display: inline-block; background-color: white; color: black;" (click)="globals.showAgendaRefreshPrompt = false; pauseAlarm()">Não</button>
    </div>

</div> -->

<div *ngIf="globals.showAlert" [@inOutAnimation] class="mainContainer">
    <div class="modal-header noBorder">
        <h5 class="modal-title msgTitle">{{globals.alert.title}}</h5>
    </div>
    <div class="modal-body noBorder">
        <p>{{globals.alert.message}}</p>
    </div>
    <div class="modal-footer noBorder">
        <button type="button" class="btn blueBtn" (click)="globals.showAlert = false">Ok</button>
    </div>
</div>

